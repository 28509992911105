import { Component, OnInit, ViewChild, HostListener, ViewContainerRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {Title} from '@angular/platform-browser';
import { Settings, AppSettings } from '../app.settings';

import { VendorSidebarMenuService } from '../services/vendor/vendor-sidebar-menu.service'
import { NavigatorService } from '../services/vendor/navigator.service';
import { AutomationsService } from '../services/vendor/automations.service';
import { InfoPanelService } from '../services/vendor/info-panel.service';
import { RebrandService } from '../services/vendor/rebrand.service'

// Page Services
import { DispatchConsoleService } from '../services/pages/dispatch-console.service';
import { WorkOrderSchedulerService } from '../services/pages/work-order-scheduler.service';
import { InvoiceService } from '../services/pages/invoice.service';
import { CMMSService } from '../services/pages/cmms.service';
import { FieldOperatorService } from '../services/pages/field-operator.service';

import { VendorAddCMMSLocationDialogComponent } from '../shared/professional/vendor-add-cmms-location-dialog/vendor-add-cmms-location-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { CMMSLocation, InfoPanel, InfoPanelTrackedDetails, PlatformInvoice, Profile } from '../data-models/models';
import { HeartBeatService } from '../services/vendor/heartbeat.service';
import { NotificationService } from '../services/vendor/notification.service';
import { VendorQuickActionMenuService } from '../services/vendor/vendor-quick-action-menu.service';
import { CompanyTimesheetsService } from 'src/app/services/vendor/company-timesheets.service';
import { InfoConfirmDialogComponent } from '../shared/info-confirm-dialog/info-confirm-dialog.component';
import { VerificationDialogComponent } from '../shared/professional/verification-dialog/verification-dialog.component';
import { TwilioHandlerService, VerificationPanelCategoryEnum } from '../services/vendor/twilio-handler.service';
import { PlatformService } from '../services/vendor/platform.service';
import { GuidedWalkthroughService } from '../services/vendor/guided-walkthrough.service';
import { NewUserWalkthrough } from './new-user-walkthrough';


@Component({
  selector: 'app-vendor-pages',
  templateUrl: './vendor-pages.component.html',
  styleUrls: ['./vendor-pages.component.scss']
})
export class VendorPagesComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav:any;  

  @ViewChild("customNavMenuRef", { read: ViewContainerRef })
    VCR: ViewContainerRef;
    
  public validMembership: boolean = false;
  
  private sessionHeartBeatInterval: number = 15000 * 60000;
  private heartBeatInterval: number = 30000;
  private addCMMSLocationDialogRef: MatDialogRef<VendorAddCMMSLocationDialogComponent>;
  private introDialogRef: MatDialogRef<InfoConfirmDialogComponent>;
  private verificationDialogRef: MatDialogRef<VerificationDialogComponent>;
  
  public verificationPanelCategories: any = VerificationPanelCategoryEnum;

  private sessionAlive: boolean = true;
  public companyIdAssigned: string = null;

  private advancedVersion: string = "2.0.0";
  public authorizedLevelV2Features: boolean = this.platformService.versionChecker(this.advancedVersion);

  public viewMode: number = 0;
  public pageMetaData: Array<any> = [];
  public toolbarTypes = [1, 2];
  public toolbarTypeOption:number;
  public headerTypes = ['default', 'image', 'carousel'];
  public headerTypeOption:string;
  public searchPanelVariants = [1, 2, 3];
  public searchPanelVariantOption:number;
  public headerFixed: boolean = false;
  public showBackToTop: boolean = false;
  public scrolledCount = 0;
  public firstActive: boolean = false;
  public isActiveMessaging: boolean = false;
  public openedChats: number = 0;
  public quickActionMenuExpanded: boolean = false;

  public introSteps: InfoPanel = {
    id: "user-agreement",
    title: 'WELCOME',
    category: 0,
    allow_close: false,
    info_panel_completed: false,
    steps: [

      {
        step_id: "a1",
        step_message: {
          title: 'WELCOME',
          message: "You're One Smart Pronoun",
          
          stepConfirmRequired: false,
          checkbox: false,
          imageMesssages: [
            {
              id: "a1b1",
              category: 1,
              headline: "Welcome to Swarm Operative",
              isImgLeftAligned: false,
              descriptors: [
                {
                  id: "a1b1c1",
                  text: "We're excited to have you here and that you've taken the first step to a smoother operation. Before we can get started we just have a few things we need to take care of.",
                  center: true
                }
              ],
              src: '../../../assets/images/vendor/platform/intro_v5.jpg',
              isImgFullScreen: true
            }

          ]
        },
        step_completed: false

      },
      {
        step_id: "a2",
        step_message: {
          title: 'Quick Start Checklist',
          message: "",
          
          stepConfirmRequired: false,
          checkbox: false,
          imageMesssages: [
            {
              id: "a2b1",
              headline: "Quick Start Checklist",
              category: 0,
              isImgLeftAligned: false,
              descriptors: [
                {
                  id: "a2b1c1",
                  text: "Register A New Company",
                  icon: "store"
                },
                {
                  id: "a2b1c2",
                  text: "Invite Employees To Swarm Or Join a Company",
                  icon: "connect_without_contact"
                },
                {
                  id: "a2b1c3",
                  text: "Let Swarm Do The Rest",
                  icon: "sports_martial_arts"
                }
              ],
              src: '../../../assets/images/others/about_us_team.jpg',
              isImgFullScreen: false
            },
            {
              id: "a2b2",
              headline: "Just A Few Rules To Follow",
              category: 1,
              isImgLeftAligned: false,
              descriptors: [
                {
                  id: "a2b2c1",
                  text: "Swarm Operative is meant to be used in a wide variety of industries to overpower the competition. However, no matter the industry we provide a secure environment to keep you in the game. In order to maintain a high level of standard we require everyone to adhere to some basic rules."
                },
                {
                  id: "a2b2c2",
                  text: "I'm Over The Age of 13",
                  confirmRequired: true
                },
                {
                  id: "a2b2c3",
                  text: "Terms & Conditions",
                  hyperlink: "https://www.swarmoperative.com/terms-conditions",
                  confirmRequired: true
                },
                {
                  id: "a2b2c4",
                  text: "Consent To Do Business Electronically",
                  hyperlink: "https://www.swarmoperative.com/esign-consent",
                  confirmRequired: true
                }
              ],
              src: '../../../assets/images/vendor/platform/intro_v3.jpg',
              isImgFullScreen: false
            },
            

            // {
            //   id: "3",
            //   headline: "Keep Going",
            //   category: 1,
            //   descriptors: [
            //     {
            //       id: "test1",
            //       text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id cursus risus, et auctor nisi. Sed lacinia, nunc vitae consectetur vulputate, erat lorem rutrum justo, vel commodo ex magna quis mi. Pellentesque lobortis condimentum tempus. In hac habitasse platea dictumst. Pellentesque eget mauris et nibh posuere interdum."
            //     }
            //   ],
            //   src: 'https://i0.wp.com/38.media.tumblr.com/49ac7a3fe63d737fb684524ec1ea6997/tumblr_mwgq1lzHJx1skne29o1_400.gif?resize=500%2C373',
            //   isImgFullScreen: true
            // },
            // {
            //   id: "4",
            //   category: 1,
            //   descriptors: [
            //     {
            //       id: "test1",
            //       text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id cursus risus, et auctor nisi. Sed lacinia, nunc vitae consectetur vulputate, erat lorem rutrum justo, vel commodo ex magna quis mi. Pellentesque lobortis condimentum tempus. In hac habitasse platea dictumst. Pellentesque eget mauris et nibh posuere interdum."
            //     }
            //   ],
            //   src: 'https://i0.wp.com/38.media.tumblr.com/49ac7a3fe63d737fb684524ec1ea6997/tumblr_mwgq1lzHJx1skne29o1_400.gif?resize=500%2C373',
            //   isImgFullScreen: true
            // },

            // {
            //   id: "5",
            //   category: 1,
            //   descriptors: [
            //     {
            //       id: "test1",
            //       text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id cursus risus, et auctor nisi. Sed lacinia, nunc vitae consectetur vulputate, erat lorem rutrum justo, vel commodo ex magna quis mi. Pellentesque lobortis condimentum tempus. In hac habitasse platea dictumst. Pellentesque eget mauris et nibh posuere interdum."
            //     }
            //   ],
            //   src: 'https://i0.wp.com/38.media.tumblr.com/49ac7a3fe63d737fb684524ec1ea6997/tumblr_mwgq1lzHJx1skne29o1_400.gif?resize=500%2C373',
            //   isImgFullScreen: false
            // }


          ]
        },
        step_completed: false

      },
      {
        step_id: "a3",
        step_message: {
          title: 'Partner Program',
          message: "",
          
          stepConfirmRequired: false,
          checkbox: false,
          imageMesssages: [
            {
              id: "a3b1",
              headline: "Partner Program",
              category: 1,
              isImgLeftAligned: true,
              descriptors: [
                {
                  id: "a3b1c1",
                  text: "Joining Swarm Operative was just the first step to maximizing your potential. We've created a Partner Program to allow you to rake in the benefits even more. You're now part of the team and you don't deserve a trimmed down referral fee. Instead, you're entitled to the following for every single business you bring to us:"
                },
                {
                  id: "a3b1c2",
                  text: "Gain 2.5% Of All Profits We Earn From Your Referral",
                  icon: "payments"
                },
                {
                  id: "a3b1c3",
                  text: "No Time Period, You Recieve These Profits Indefinitely",
                  icon: "currency_exchange"
                },
                {
                  id: "a3b1c4",
                  text: "Unlimited Referrals Allowed",
                  icon: "rocket_launch"
                }
              ],
              src: '../../../assets/images/vendor/platform/partner_program.jpg',
              isImgFullScreen: false
            }
          ]
        },
        step_completed: false

      },
      {
        step_id: "a4",
        step_message: {
          title: 'You Made It',
          message: "",
          
          stepConfirmRequired: false,
          checkbox: false,
          imageMesssages: [
            {
              id: "a4b1",
              headline: "You Made It",
              category: 1,
              isImgLeftAligned: false,
              descriptors: [
                {
                  id: "a4b1c1",
                  text: "Congratulations, prepare for life to be so much better!",
                  center: true
                }
              ],
              src: "../../../assets/images/vendor/platform/initialize_v2.webp",
              isImgFullScreen: true
            }
          ]
        },
        step_completed: false

      }
    ]
  }
  
  public footerPageTitle: string;
  public navPage: number = 8;

  public navPageInfo: Array<any> = [
    {
      pageId: 0,
      sidebarMenu: false
    },
    {
      pageId: 1,
      sidebarMenu: false
    },
    {
      pageId: 2,
      sidebarMenu: false
    },
    {
      pageId: 3,
      sidebarMenu: false
    },
    {
      pageId: 4,
      sidebarMenu: true
    },
    {
      pageId: 5,
      sidebarMenu: true
    },
    {
      pageId: 6,
      sidebarMenu: false
    },
    {
      pageId: 7,
      sidebarMenu: false
    },
    {
      pageId: 8,
      sidebarMenu: false
    },
    {
      pageId: 9,
      sidebarMenu: false
    },
    {
      pageId: 10,
      sidebarMenu: false
    },
    {
      pageId: 11,
      sidebarMenu: false
    }
  ];

  public isCMMSLocationMenuOpen: boolean = false;
  public CMMSLocations: Array<CMMSLocation> = [];
  public CMMSFocusLocations: Array<CMMSLocation> = [];
  public isCMMSInitialized: boolean = false;
  // Inventory Section
  public cmmsPages: Array<any> = [
    { 
      pageTitle: "Inventory",
      pageId: 0,
      group: 0
    },
    { 
      pageTitle: "Assets",
      pageId: 1,
      group: 0
    },
    { 
      pageTitle: "Work Orders",
      pageId: 2,
      group: 1
    },
    // { 
    //   pageTitle: "Reports",
    //   pageId: 3,
    //   group: 1
    // },
    { 
      pageTitle: "Requests",
      pageId: 4,
      group: 1
    },
    { 
      pageTitle: "Meter Readings",
      pageId: 5,
      group: 1
    }

    // { 
    //   pageTitle: "Files",
    //   pageId: 6,
    //   group: 2
    // }
  ];

  public benchstockPages: Array<any> = [
    { 
      pageTitle: "Inventory",
      pageId: 0,
      group: 0
    },
    { 
      pageTitle: "Assets",
      pageId: 1,
      group: 0
    },
    { 
      pageTitle: "Reports",
      pageId: 3,
      group: 1
    },
    { 
      pageTitle: "Requests",
      pageId: 4,
      group: 1
    }
  ];
  
  public settings: Settings;

  private newUserGuidedWalkthrough: NewUserWalkthrough = new NewUserWalkthrough();
  
  
  constructor(
    public appSettings:AppSettings, 
    public router:Router, 
    private platformService: PlatformService,
    private navigatorService: NavigatorService, 
    private companyTimesheets: CompanyTimesheetsService,
    private notificationsService: NotificationService,
    private automationsService: AutomationsService,
    private heartBeatService: HeartBeatService, 
    private rebrandService: RebrandService,
    private sidebarMenuService: VendorSidebarMenuService,
    private quickActionService: VendorQuickActionMenuService,
    private twilioHandler: TwilioHandlerService,
    private titleService: Title, 
    public dialog: MatDialog,
    private guidedWalkthroughService: GuidedWalkthroughService,
    
    
  ) {
    
    this.settings = this.appSettings.settings;  

    // Required in the constructor or the data isn't ready in time for the creation of the DOM to build the necessary elements
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.setCMMSViewMode(this.router.routerState, this.router.routerState.root);

        console.log("View Mode: ", this.viewMode);

        let ids: Array<number> = this.getId(this.router.routerState, this.router.routerState.root);
        let id: number = ids[ids.length - 1];

        let titles: Array<string> = this.getTitle(this.router.routerState, this.router.routerState.root);
        let title: string = titles[titles.length - 1];

        let platformStates: Array<number> = this.getPlatformState(this.router.routerState, this.router.routerState.root);
        let platformState: number = platformStates[platformStates.length - 1];

        this.titleService.setTitle(title);
        this.platformService.setPlatformState(platformState);
        this.footerPageTitle = title;
        this.navPage = id;

        this.firstActive = true;
      }
    });
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {

    // this.initCustomTheme(); 
    
    this.initialize();

  } 

  ngAfterViewInit() {
    let self = this;
    let profile: Profile = this.navigatorService.getCurrentProfile();
    
    this.rebrandService.initTheming();
    this.guidedWalkthroughService.setGuidedPlatformWalkthrough(this.newUserGuidedWalkthrough.getWalkthrough());
    
    document.getElementById('preloader').classList.add('hide');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.sidenav?.close();
        this.settings.mainToolbarFixed = false;
        setTimeout(() => {
          window.scrollTo(0,0);
        }); 
      }            
    });    

    setTimeout(function() {
      document.querySelector("ng-chat-friends-list")?.firstElementChild.classList.add('messageHide');
      document.querySelector("ng-chat-friends-list")?.firstElementChild.classList.add('d-none');
      document.querySelector(".messageIcon")?.classList.add('messageIconShow');

      // Register Listeners
      self.quickActionMenuExpanded = self.quickActionService.getQuickActionMenuToggle();
      self.listeners();
    }, 500);
    

    if(profile.company_id.length == 0) {
      this.quickActionMenuExpanded = false;
    }

    this.verifyEmailStatus();
    
  }  

  ngAfterContentInit() {
    this.initialFooterTitle();
  }

  ngOnChanges() {

  }

  private initialize(): void {

    if(this.validMembership) {

      this.platformService.init();
      this.automationsService.init();
      this.notificationsService.initializeNotifications();

      if(this.authorizedLevelV2Features) {
        this.twilioHandler.generateVoiceAccessToken(); 
      }
      
      this.monitorCompanyId();

      // this.twilioHandler.generateOutboundCallApplication();
      // this.twilioHandler.generateVoiceAPIKey();
      
      // this.twilioHandler.testGenerateVoiceAccessToken();

      // Used to logout the session, when browser window was closed 
      // window.addEventListener('beforeunload', function (e) {
      //   // Cancel the event
      //   e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown

      //   self.endSession();

      //   // Chrome requires returnValue to be set
      //   e.returnValue = '';
      // });

      // window.onload=function(){

      //   document.getElementById('refresh').onclick=function() {
      //       window.onbeforeunload = null;

      //       self.endSession();

      //       window.location.reload(); // replace with your code
      //   }
        
      //   window.onbeforeunload = function() {        
      //       return "Are you sure you want to leave?";        
      //   }
        
      // }
      
      // window.onbeforeunload = function(e) {
      //   // e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown

      //   // self.endSession();

      //   self.endSession();

      //   // Chrome requires returnValue to be set
      //   // e.returnValue = "Refreshing or leaving will terminate your session!";
        
      //   return "Refreshing or leaving will terminate your session!";
      // }

      // window.
      
      window.onunload = function() {
        this.endSession();
        
        return "Refreshing or leaving will terminate your session!";
      }

      this.toolbarTypeOption = this.settings.toolbar;    
      this.headerTypeOption = this.settings.header; 
      this.searchPanelVariantOption = this.settings.searchPanelVariant;

      let userId: string = this.navigatorService.getProfileId();
      let companyId: string = this.navigatorService.getCompanyId();

      if(
        this.sessionAlive && 
        userId != undefined && 
        userId != null && 
        companyId != undefined && 
        companyId != null &&
        companyId != userId && 
        userId.length > 0 &&
        companyId.length > 0) {

          this.getAllCMMSLocations();

        // Dev Note: Temporarily turned off heartbeat due to fatal error
        // this.sessionTrackerHeartbeat();
        // this.serverHeartbeat();
        this.companyTimesheets.createDailyCompanyTimeChart();
        
      }

    }


  }

  public validateMembership(validMembership: boolean): void {

    this.validMembership = validMembership;
    this.initialize();

  }

  private twentyFourHourCheck(dateCheck: Date): boolean {
    let isWithinDay: boolean = false;

    if(dateCheck != undefined && dateCheck != null) {
      let minute: number = 1000;
      let hour: number = minute * 60;
      let day: number = hour * 24;

      let currentDay: Date = new Date();

      isWithinDay = (currentDay.getTime() - dateCheck.getTime() ) <= day;
    }

    return isWithinDay;
  }

  verifyEmailStatus(): void {
    let profile: Profile = this.navigatorService.getCurrentProfile();

    if( (profile?.email_verified == undefined || profile?.email_verified == null)) {
      this.openEmailVerificationDialog();
    } else {
      this.getUserAgreement();
    }

  }

  getUserAgreement(): void {

    this.navigatorService.getAsyncUserAgreement().then( (userAgreement: InfoPanelTrackedDetails) => {
      
      if(userAgreement == undefined || userAgreement == null) {
        this.openIntroDialog();
      } else {

        let profile: Profile = this.navigatorService.getCurrentProfile();

        if(
          (!profile?.linked_accounts || profile?.linked_accounts?.length == 0) 
          && 
          (!profile?.linked_account_requests || profile?.linked_account_requests?.length == 0)) {
          this.guidedWalkthroughService.initiateGuidedPlatformStep();
        }

      }

    })

  }

  async endSession(): Promise<void> {
    this.sessionAlive = false;

    let profile: Profile = this.navigatorService.getCurrentProfile();
    profile.online_status = 3;

    await this.navigatorService.updateProfile(profile).then( async data => {

      await this.heartBeatService.getGeneralAsyncDataSync().then( async (dataSync: any) => {
        dataSync.employees = new Date();

        await this.heartBeatService.updateGeneralDataSync(dataSync);

      });

    });

  }

  async sessionTrackerHeartbeat(): Promise<void> {
    let self: any = this;
    await this.heartBeatService.updateAuthenticatedUserSessionSync().then( data => {
      this.heartBeatService.getCompanySessionSync();
    });
    
    setTimeout( function() {
      let userId: string = self.navigatorService.getProfileId();

      if(self.sessionAlive && userId != undefined && userId != null && userId.length > 0) {
        self.sessionTrackerHeartbeat();
      }
    }, this.sessionHeartBeatInterval);

  }

  serverHeartbeat(): void {
    let self: any = this;

    if(
      this.navigatorService.getCompanyId() != undefined && 
      this.navigatorService.getCompanyId() != null &&
      this.navigatorService.getCompanyId().length > 0 &&
      this.navigatorService.getCompanyId() != this.navigatorService.getProfileId()) {
        this.heartBeatService.getGeneralDataSync();
    }

    this.heartBeatService.getUserDataSync();

    setTimeout( function() {

      let userId: string = self.navigatorService.getProfileId();

      if(self.sessionAlive && userId != undefined && userId != null && userId.length > 0) {
        self.serverHeartbeat();
      }
      
    }, this.heartBeatInterval);

  }

  private monitorCompanyId(): void {
    let self = this;

    setTimeout(function() {
      let profile: Profile = self.navigatorService.getCurrentProfile();

      if(profile.company_id != undefined && profile.company_id != null) {
        self.companyIdAssigned = profile.company_id;
      }

      self.monitorCompanyId();
    }, 1000);

  }

  listeners() {
    let IMTitle: any = document.querySelector("ng-chat-friends-list")?.firstElementChild;
    // let chats: any = document.querySelector(".ng-chat-window");
    let instantMessaging: any = document.querySelector(".ng-chat-title");
    var ctx = this;

    instantMessaging?.addEventListener('click', function() {
      document.querySelector(".messageIcon")?.classList.remove('messageIconHide');
      document.querySelector(".messageIcon")?.classList.add('messageIconShow');

      IMTitle?.classList.remove('messageShow');
      IMTitle?.classList.add('messageHide');
      IMTitle?.classList.add('d-none');
      ctx.isActiveMessaging = false;
    });


    this.quickActionService.quickActionMenuToggleBroadcaster().subscribe( (expanded: boolean) => {

      this.quickActionMenuExpanded = expanded;

    });

    // for(let chat of chats) {
    //   chat.addEventListener('click', function() {
    //     console.log("Chat Click");
    //   });
    // }
  }

  private getPlatformState(state, parent): Array<number> {
    let data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.state) {
      data.push(parent.snapshot.data.state);
    }

    if(state && parent) {
      data.push(... this.getPlatformState(state, state.firstChild(parent)));
    }

    this.pageMetaData = JSON.parse( JSON.stringify(data) );
    console.log("Router Page Meta Data: ", this.pageMetaData);

    return data;
  }

  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }

    this.pageMetaData = JSON.parse( JSON.stringify(data) );
    console.log("Router Page Meta Data: ", this.pageMetaData);

    return data;
  }

  getId(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.id) {
      data.push(parent.snapshot.data.id);
    }

    else if(state && parent) {
      data.push(... this.getId(state, state.firstChild(parent)));
    }

    else {
      data.push(0);
    }

    return data;
  }

  public initialFooterTitle() {
      var title = this.getTitle(this.router.routerState, this.router.routerState.root)[0];
      this.titleService.setTitle(title);

      this.footerPageTitle = title;
  }

  // ==============================================
  //                  CMMS Calls
  // ==============================================
  public setCMMSViewMode(state: any, parent: ActivatedRoute) {
    if(parent != undefined && parent.snapshot != undefined && parent.snapshot.data != undefined && parent.snapshot.data.viewMode != undefined) {
      this.viewMode = parent.snapshot.data.viewMode;
    }

    if(state != undefined && parent != undefined) {
      this.setCMMSViewMode(state, state.firstChild(parent));
    }
  }

  public updateFocusedCMMSLocations(location: CMMSLocation, isChecked: any): void {
    if(isChecked) {
      this.CMMSFocusLocations.push(location);
    } else {
      let locationIndex = this.CMMSFocusLocations.findIndex( (element) => element.id == location.id);
      this.CMMSFocusLocations.splice(locationIndex, 1);
    }

    this.sidebarMenuService.setFocusedCMMSLocations(this.CMMSFocusLocations);
  }

  public getAllCMMSLocations(): void {
    this.navigatorService.getAsyncAllCMMSLocations().then(locations => {
      let profile: Profile = this.navigatorService.getCurrentProfile();

      this.CMMSLocations = JSON.parse(JSON.stringify(locations));

      this.CMMSLocations.push({
        originId: profile.id,
        originDate: new Date(),
        id: profile.id,
        isDisplayed: true,
        title: profile.first_name + " " + profile.last_name,
        desc: '',
        address: {
            street: profile.address.street,
            city: profile.address.city,
            state: profile.address.state,
            zip: profile.address.zip
        },
        images: null,
        type: null
      });

      if(!this.isCMMSInitialized) {
        this.CMMSFocusLocations = JSON.parse(JSON.stringify(locations));
      }

      
    });
  }

  public openCMMSLocationMenu(): void {
    this.isCMMSLocationMenuOpen = true;
  }

  public closeCMMSLocationMenu(): void {
    this.isCMMSLocationMenuOpen = false;
  }
  
  public changeTheme(theme){
    this.settings.theme = theme; 
    this.getFooterTitle();      
  }

  public chooseToolbarType(){
    this.settings.toolbar = this.toolbarTypeOption;
    window.scrollTo(0,0);
  }

  public chooseHeaderType(){
    this.settings.header = this.headerTypeOption;    
    window.scrollTo(0,0);
    this.router.navigate(['/']);
  }

  public chooseSearchPanelVariant(){
    this.settings.searchPanelVariant = this.searchPanelVariantOption;
  }

  public getFooterTitle(): void {
    this.navigatorService.getFooterTitle().subscribe(activePageTitle => this.footerPageTitle = activePageTitle);
  }
     
  @HostListener('window:scroll') onWindowScroll() {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    (scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false; 

    if(this.settings.stickyMenuToolbar){      
      let top_toolbar = document.getElementById('top-toolbar');
      if(top_toolbar){ 
        if(scrollTop >= top_toolbar.clientHeight) {
          this.settings.mainToolbarFixed = true;
        }
        else{
          this.settings.mainToolbarFixed = false;
        } 
      }        
    } 
    
        
    let load_more = document.getElementById('load-more');
    if(load_more){
      if(window.innerHeight > load_more.getBoundingClientRect().top + 120){ 
        if(!this.settings.loadMore.complete){
          if(this.settings.loadMore.start){        
            if(this.scrolledCount < this.settings.loadMore.step){  
              this.scrolledCount++; 
              if(!this.settings.loadMore.load){ 
                this.settings.loadMore.load = true; 
              }
            }
            else{
              this.settings.loadMore.start = false;
              this.scrolledCount = 0;
            }
          }  
        }              
      }
    }
  }

  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval); 
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { window.scrollTo(0,0) });
    }
  }

  autoDisplayMessaging() {
    if(!this.isActiveMessaging) {
      let IMTitle:any = document.querySelector("ng-chat-friends-list").firstElementChild;
      IMTitle.classList.remove('d-none');

      this.eventFire(document.querySelector(".ng-chat-title"), 'click');
      
      IMTitle.classList.remove('messageHide');
      IMTitle.classList.add('messageShow');

      document.querySelector(".messageIcon").classList.remove('messageIconShow');
      document.querySelector(".messageIcon").classList.add('messageIconHide');

      this.isActiveMessaging = true;
    } else {

      this.isActiveMessaging = false;
    }
  }

  updateOpenedChatCount(chatCounter: number) {
    this.openedChats = chatCounter;

    console.log("Opened Chats: ", this.openedChats);
  }

  openNewCMMSLocationDialog(): void {
    console.log("Open New CMMS Location");

    this.addCMMSLocationDialogRef = this.dialog.open(
      VendorAddCMMSLocationDialogComponent
    );

    this.addCMMSLocationDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");
      console.log("New CMMS Location: " + data);

      this.navigatorService.upsertCMMSLocation(data);
    })
  }

  openIntroDialog(): void {
    console.log("Open Intro Dialog");

    this.introDialogRef = this.dialog.open(
      InfoConfirmDialogComponent,
      { 
        data: this.introSteps
      }
    );

    this.introDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");
      console.log("Intro Dialog: ", data);

      this.navigatorService.updateUserAgreement(data).then(() => {

        this.guidedWalkthroughService.setGuidedPlatformWalkthrough(this.newUserGuidedWalkthrough.getWalkthrough());
        this.guidedWalkthroughService.initiateGuidedPlatformStep();

      });
    })
  }

  openEmailVerificationDialog(): void {
    console.log("Open Email Verification Dialog");

    this.verificationDialogRef = this.dialog.open(
      VerificationDialogComponent,
      { 
        data: this.verificationPanelCategories.EMAIL
      }
    );

    this.verificationDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");
      console.log("Email Verification Dialog: ", data);


      let profile: Profile = this.navigatorService.getCurrentProfile();
      profile.email_verified = new Date();

      this.navigatorService.updateProfile(profile).then( (status: boolean) => {

        if(status) {
          this.getUserAgreement();
        }

      });

    })
  }


  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}










































//   public showBackToTop: boolean = false;
//   public scrolledCount = 0;

//   public settings: Settings;
//   constructor(public appSettings:AppSettings, public router:Router) {
//     this.settings = this.appSettings.settings;  
//   }

//   ngOnInit() {
    
//   }
  
//   public changeTheme(theme){
//     this.settings.theme = theme;       
//   }

//   @HostListener('window:scroll') onWindowScroll() {

//     const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
//     (scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false; 

//     if(this.settings.stickyMenuToolbar){      
//       let top_toolbar = document.getElementById('top-toolbar');
//       if(top_toolbar){ 
//         if(scrollTop >= top_toolbar.clientHeight) {
//           this.settings.mainToolbarFixed = true;
//         }
//         else{
//           this.settings.mainToolbarFixed = false;
//         } 
//       }        
//     } 

//   let load_more = document.getElementById('load-more');
//     if(load_more){
//       if(window.innerHeight > load_more.getBoundingClientRect().top + 120){ 
//         if(!this.settings.loadMore.complete){
//           if(this.settings.loadMore.start){        
//             if(this.scrolledCount < this.settings.loadMore.step){  
//               this.scrolledCount++; 
//               if(!this.settings.loadMore.load){ 
//                 this.settings.loadMore.load = true; 
//               }
//             }
//             else{
//               this.settings.loadMore.start = false;
//               this.scrolledCount = 0;
//             }
//           }  
//         }              
//       }
//     }
//   }

//   public scrollToTop(){
//     var scrollDuration = 200;
//     var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
//     var scrollInterval = setInterval(()=>{
//       if(window.pageYOffset != 0){
//          window.scrollBy(0, scrollStep);
//       }
//       else{
//         clearInterval(scrollInterval); 
//       }
//     },10);
//     if(window.innerWidth <= 768){
//       setTimeout(() => { window.scrollTo(0,0) });
//     }
//   }

//   ngAfterViewInit(){
//     document.getElementById('preloader').classList.add('hide');
//     this.router.events.subscribe(event => {
//       if (event instanceof NavigationEnd) {        
//         // this.sidenav.close();
//         this.settings.mainToolbarFixed = false;
//         setTimeout(() => {
//           window.scrollTo(0,0);
//         }); 
//       }            
//     });    
//   }
// }
